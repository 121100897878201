import { Injectable } from '@angular/core';
import * as Stomp from 'stompjs';
import * as SockJS from 'sockjs-client';
import { environment } from '../../../environments/environment';
import { ProductService } from '../product/product.service';
import { Router } from '@angular/router';
import { HomePage } from 'src/app/page/home/home.page';
import { Storage } from '@ionic/storage';
import { stringify } from 'querystring';
import { ModalController, NavController, Platform } from '@ionic/angular';
import { StatusPaymentPage } from 'src/app/page/status-payment/status-payment.page';
import { CartDataClass } from 'src/app/model/cart-data';

export enum DesignNames {
  Blank, Dispensing, ThankYou, SessionExpired, ConnectionTimeout, ConnectionNotAvailable, DuplicateSession, ErrorFetchingProducts
}

@Injectable({
  providedIn: 'root'
})
export class ConnectService {

  stompClient: any;
  trioAccessToken: String;
  currentDesign = DesignNames.Blank.toString();
  paymentStatusCode = 0;
  public connected: boolean = false;
  trioScanQRShowHide: boolean = false;
  userType: boolean = false;
  autoRedirectAllow: boolean = false;

  firstName: null;
  lastName: null;
  fullName: any;
  imageUrl: any;
  userEmail: null;
  phoneNumber: null;
  countryCode: null;
  socialLoginAuthToken: null;
  socialLoginId: null;
  userToken: string
  myTimeout: any

  userLoginType: string;
  userId: string;
  dispenseSession: boolean = false;
  platformType: string = "";
  // Api links
  //baseUrl = "http://192.168.1.46:8080/api/"

  //baseUrl = "https://vmsapiprealpha.trinityaxistrio.com/api/"
  //SA
  baseUrl = environment.baseUrl

  //baseUrl = "https://vmsapialpha.trinityaxistrio.com/api/"
  loginApiLink = this.baseUrl + "triocust/auth"
  getUpdateProfileApi = this.baseUrl + "triocust/user/current"
  getOrderListApi = this.baseUrl + "triocust/sale/orders"
  getReceiptDownloadApi = this.baseUrl + "openAPI/sale/getreceipt/"
  getAgreementCheckApi = this.baseUrl + "triocust/user/agreement/check"
  getOrderStatusApi = this.baseUrl + "openAPI/sale/getOrederStatus/";//GET /openAPI/sale/getOrederStatus/{encodedOrderId}
  getUpdateOrderSyncApi = this.baseUrl + "triocust/sale/orders/" //PUT /triocust/sale/orders/{orderId}/sync
  getTotalRewardApi = this.baseUrl + "triocust/totalReward/"
  getRewardHistorydApi = this.baseUrl + "triocust/rewardHistory/"
  trioOrderId: String;
  trioCustId: String;
  taxAmount: any;
  constructor(private platform: Platform, private navCtrl: NavController, private storage: Storage, private productService: ProductService, private router: Router, public homePage: HomePage, public modalCtrl: ModalController) {

    this.storage.create()
    //  this.userTypeMethod()

  }

  initWsConnect() {
    let ws = new SockJS(environment.socketURL);
    const _this = this;
    this.stompClient = Stomp.over(ws);
    this.productService.stompClient = this.stompClient;

    // this.stompClient.debug = () => { };

    if (!_this.connected)
      _this.stompClient.connect({}, function (frame) {
        _this.connected = true;
        _this.productService.startIntervalFun()
        _this.stompClient.subscribe('/trio-remote/check/' + _this.trioAccessToken, function (frame) {
          console.log("initWsConnect====1==" + frame.body);
          const dispenseStatus = JSON.parse(frame.body);
          if (dispenseStatus == true)
            _this.createSubscriptions();
          else {
            _this.trioAccessToken = undefined;
            _this.currentDesign = DesignNames.SessionExpired.toString();
            if (_this.platform.is('android')) {
              _this.router.navigate(['status-display']);
            } else {
              _this.navCtrl.navigateRoot('status-display');
            }
          }
        });
      }, (err) => {
        _this.connected = false;
        _this.productService.stopIntervalFun()
        this.reConnect(err);
      });

  }

  reConnect(error) {
    setTimeout(() => {
      this.initWsConnect();
    }, 1000);
  }

  createSubscriptions() {
    this.listernDispenseStatus();
  }

  listernDispenseStatus() {
    const _this = this;
    this.stompClient.subscribe('/trio-remote/product/dispense/status/' + this.trioAccessToken, function (frame) {
      //here to get the dispense status
      const dispenseStatus = JSON.parse(frame.body);
      ///{"dispensed":false,"statusCode":1,"message":null,"vmID":"VMTAM01","androidId":null}


      if (dispenseStatus?.dispensed == true) {
        _this.dispenseSession = true
        _this.currentDesign = DesignNames.ThankYou.toString();
        _this.disconnect();
        _this.dispenseSession = false;
        _this.connected = false;
        _this.productService.stopIntervalFun()
        if (_this.platformType == "mobile") {
          _this.autoRedirectAllow = true
          setTimeout(() => {
            if (_this.autoRedirectAllow) {
              _this.autoRedirectAllow = false
              _this.currentDesign = DesignNames.SessionExpired.toString();
              if (_this.platform.is('android') || _this.platform.is('ios')) {
                if (_this.platformType == "mobile") {
                  _this.router.navigate(['tabs'], { replaceUrl: true })
                } else {
                  _this.navCtrl.navigateRoot('status-display');
                }

              } else {
                _this.navCtrl.navigateRoot('status-display');
              }

            }
          }, 5000);

        }else{
          _this.navCtrl.navigateRoot('status-display');
        }
      } else {
        //Payment status handel here

        // 1 - Payment success
        // 2 - Payment failure
        // 3 - dispense success
        // 4 - dispense failure
        if (dispenseStatus?.statusCode == 1 && dispenseStatus?.dispensed == false) {
          //payment success 
          _this.paymentStatusCode = 1

          setTimeout(() => {
            //this.dismiss()
            _this.currentDesign = DesignNames.Dispensing.toString();
            // this.router.navigate(['status-display']);
            if (_this.platform.is('android')) {
              _this.router.navigate(['status-display']);
            } else {
              _this.navCtrl.navigateRoot('status-display');
            }
          }, 3000);
        } else {
          //payment failiur            
          _this.paymentStatusCode = 2
          if (_this.platformType == "mobile") {
            _this.autoRedirectAllow = true
            setTimeout(() => {
              //this.dismiss()
              _this.dispenseSession = true
              _this.disconnect();
              _this.dispenseSession = false;
              _this.connected = false;
              _this.productService.stopIntervalFun()
              _this.currentDesign = DesignNames.SessionExpired.toString();
              if (_this.autoRedirectAllow) {
                _this.autoRedirectAllow = false
                if (_this.platform.is('android') || _this.platform.is('ios')) {
                  if (_this.platformType == "mobile") {
                    console.log("tabs page")
                    _this.router.navigate(['tabs'], { replaceUrl: true })
                  } else {
                    _this.navCtrl.navigateRoot('status-display');
                  }

                } else {
                  _this.navCtrl.navigateRoot('status-display');
                }

              }
            }, 3000);

          } else {
            if(_this.productService.showRemainingTimeMin=="00" && _this.productService.showRemainingTimeSec=="00"){
              _this.autoRedirectAllow = true
              setTimeout(() => {
                //this.dismiss()
                _this.dispenseSession = true
                _this.disconnect();
                _this.dispenseSession = false;
                _this.connected = false;
                _this.productService.stopIntervalFun()
                if (_this.autoRedirectAllow) {
                  _this.autoRedirectAllow = false
                  _this.currentDesign = DesignNames.ConnectionTimeout.toString();
                  _this.navCtrl.navigateRoot('status-display');
                }
              }, 3000);
            }else{
              _this.autoRedirectAllow = true
              setTimeout(() => {
              _this.dispenseSession = false;
              _this.navCtrl.navigateRoot('cart-details');
            }, 3000);
            }
          }
        }
      }

      // _this.paymentStatusCode = 0

    });
  }

  register() {
    return new Promise((resolve, reject) => {
      const _this = this;
      this.stompClient.subscribe('/trio-remote/connect/' + this.trioAccessToken, function (frame) {
        let result = JSON.parse(frame.body);
        console.log("connected.....result===" + JSON.stringify(result));
        if (result.errorCode != 0) {
          if (result.errorCode == 404)
            _this.currentDesign = DesignNames.ConnectionNotAvailable.toString();
          else if (result.errorCode == 409){
            _this.connected=false;
            _this.currentDesign = DesignNames.DuplicateSession.toString();
          }
          else if (result.errorCode == 500){
            _this.connected=false;
            _this.currentDesign = DesignNames.ErrorFetchingProducts.toString();
            _this.disconnect();
          }
          //_this.router.navigate(['status-display']);
          if (_this.platform.is('android')) {
            _this.router.navigate(['status-display']);
          } else {
            _this.navCtrl.navigateRoot('status-display');
          }
          reject(false);
        } else {
          console.log("register2" + result.availableProducts);
          _this.productService.availableProducts = result.availableProducts;

          console.log("result" + result);
          console.log("result.orgLogoUrl==========" + result.orgLogoUrl);
          console.log("result.branchName==========" + result.branchName);
          console.log("result.zoneTime==========" + result.zoneTime);
          console.log("result.vmId==========" + result.vmId);
          console.log("result.paymentMethod==========" + result.paymentMethod);
          console.log("result.currencySymbol==========" + result.currencySymbol);
          console.log("result.countryName==========" + result.countryName);
          console.log("result.countryNameCode==========" + result.countryNameCode);
          console.log("result.taxCollectable==========" + result.taxCollectable);
          

          // _this.storage.set('vmOrgLogo', result.orgLogoUrl);
          // _this.storage.set('vmBranchName', result.branchName);
          // _this.storage.set('vmLocalTime', result.zoneTime);
          // _this.storage.set('vmId', result.vmId);

          // this.storage.get('vmId').then((val) => {
          //   console.log("vmId ============== " + val);
          // });

          _this.productService.vmOrgLogo = result.orgLogoUrl;
          _this.productService.vmBranchName = result.branchName;
          _this.productService.vmLocalTime = result.zoneTime;
          _this.productService.vmId = result.vmId;
          _this.productService.paymentMethod = result.paymentMethod;
          _this.productService.vmCurrencySymbol = result.currencySymbol;
          _this.productService.countryName = result.countryName;
          _this.productService.countryNameCode = result.countryNameCode;
          _this.productService.taxCollectable = result.taxCollectable;
          



          _this.checkForAds();
          _this.startConnectionTimeOut();
          resolve(true);
        }
      }, (err) => {
        reject(err);
      });

    });
  }

  disconnect() {
    if (this.stompClient != null) {
      this.stompClient.disconnect();
      this.productService.availableProducts = [];
      this.productService.cartProducts = [];
      this.trioAccessToken = undefined;
      this.dispenseSession = false;
      clearTimeout(this.myTimeout)
      this.productService.stopIntervalFun()
    }
  }

  checkForAds() {
    const _this = this;
    this.stompClient.subscribe('/trio-remote/ad', function (frame) {
      //here to get the Advertisement List
      console.log("/trio-remote/ad frame=== " + frame.body);

      _this.homePage.advList = JSON.parse(frame.body);
      _this.homePage.toSetAdvList();
    }, (err) => {
      console.log("/trio-remote/ad err=== " + err);
    });
  }

  startConnectionTimeOut() {
    const _this = this
    this.myTimeout =
      setTimeout(() => {
        console.log("connected===" + this.currentDesign + "dispensestatus====" + _this.dispenseSession + "connectedstatus====" + _this.connected);
        if (_this.connected && !_this.dispenseSession) {
          this.currentDesign = DesignNames.ConnectionTimeout.toString();
          this.router.navigate(['status-display']);
          this.disconnect();
        }
      }, 1000 * 60 * 5); //this should come actualy for 5min trigger
  }

  async showModal() {
    const modal = await this.modalCtrl.create({
      component: StatusPaymentPage
    });
    return await modal.present();
  }
  dismiss() {
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    this.modalCtrl.dismiss({
      'dismissed': true
    });
  }

  getCartTaxDetail() {
    return new Promise((resolve, reject) => {
    var cartDataModel = new CartDataClass();
    this.trioOrderId = this.productService.getNextTrioOrderID();
    cartDataModel.trioOrderId = this.productService.trioOrderId;
    cartDataModel.trioCustId = this.userId;
    cartDataModel.productDispenseInfo = this.productService.cartProducts;
    this.stompClient.send('/product/calcVmProdTax', {}, JSON.stringify(cartDataModel));
  
  const _this = this;
    this.stompClient.subscribe('/trio-remote/product/calcVmProdTax/'+this.trioAccessToken, function (frame) {
      let taxResponse=JSON.parse(frame.body);
      _this.taxAmount=JSON.stringify(taxResponse.tax.amountToCollect);
      return _this.taxAmount;
    }, (err) => {
      console.log("/trio-remote/product/calcVmProdTax/ err=== " + err);
    });
  });
  }

}
