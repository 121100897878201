import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  AdvertisementClass,
  AdvertisementModel,
} from 'src/app/model/advertisement-model';
import { ProductBasicInfo } from 'src/app/model/available-products';
import { ProductService } from 'src/app/service/product/product.service';
import { Injectable } from '@angular/core';
import {
  BarcodeScanner,
  BarcodeScannerOptions,
  BarcodeScanResult,
} from '@ionic-native/barcode-scanner/ngx';
import { ConnectService } from 'src/app/service/connect/connect.service';
import { ToastController, Platform, AlertController } from '@ionic/angular';
import { NetworkService } from 'src/app/service/utility/network.service';
@Injectable({
  providedIn: 'root',
})
@Component({
  selector: 'app-home',
  templateUrl: 'home.page.html',
  styleUrls: ['home.page.scss'],
})
export class HomePage implements OnInit {
  availableProducts: Array<ProductBasicInfo> = [];
  advList: Array<AdvertisementModel> = [];
  activeMediaType: number;
  activeURL: string = '';
  defaultAdImage: string = 'assets/images/splash.png'; //image
  defaultAdVideo: string = 'assets/videos/trio_ad.mp4'; //video
  defaultAdVideo2: string = 'assets/videos/diarymilk.mp4'; //video
  connectService: ConnectService;
  topSellingProducts: any = [];
  advActivePosition = -1;

  constructor(
    public alertController: AlertController,
    private platform: Platform,
    private router: Router,
    public productService: ProductService,
    private barcodeScanner: BarcodeScanner,
    public toastController: ToastController,
    private connectivity: NetworkService
  ) {}

  ngOnInit() {
    //this.availableProducts = this.productService.availableProducts;
    this.topSellingProducts = this.productService.topSellingProducts;
    // this.connectService = this.ConnectService
    // this.toSetAdvList();
  }

  ionViewDidEnter() {
    //console.log(">>>>>>ionViewDidEnter>>>>>>>>>", this.advList);
  }

  ionViewWillEnter() {
    //console.log(">>>>>>ionViewWillEnter>>>>>>>>>", this.advList);
  }

  getWholeNumber(decimalNum: number) {
    return decimalNum.toString().split('.')[0];
  }

  getFractionalNumber(decimalNum: number) {
   return decimalNum.toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 }).split('.')[1];
  }

  goToProductDetails(productId: number, productPrice: number) {
    this.productService.selectedProductId = productId;
    this.productService.selectedProductPrice = productPrice;
    this.router.navigate(['product-details']);
  }
  toSetAdvList() {
    // 4 is checked, since we have only 4 slots

    if (this.advList.length < 4) {
      console.log('>>>>>>before push>>>>>>>>>', this.advList.length);
      console.log("inside length 4")
        if (!this.advList.find((x) => x.campaignSlot == 4)) {
          console.log("inside slot 4")
          let tempAdv: AdvertisementModel = new AdvertisementClass();

          tempAdv.adMediaType = 2;
          tempAdv.campaignDate = '';
          tempAdv.campaignId = 0;
          tempAdv.campaignSlot = 4;
          tempAdv.campaignTimeLineId = 0;
          tempAdv.mediaId = 11;
          tempAdv.mediaType = 0;
          tempAdv.timeSlot = 0;
          tempAdv.url = this.defaultAdVideo;
          this.advList.push(tempAdv);
        }

        if (!this.advList.find((x) => x.campaignSlot == 3)) {
          console.log("inside slot 3")
          let tempAdv: AdvertisementModel = new AdvertisementClass();

          tempAdv.adMediaType = 2;
          tempAdv.campaignDate = '';
          tempAdv.campaignId = 0;
          tempAdv.campaignSlot = 3;
          tempAdv.campaignTimeLineId = 0;
          tempAdv.mediaId = 11;
          tempAdv.mediaType = 0;
          tempAdv.timeSlot = 0;
          tempAdv.url = this.defaultAdVideo;
          this.advList.push(tempAdv);
        }

        if (!this.advList.find((x) => x.campaignSlot == 2)) {
          console.log("inside slot 2")
          let tempAdv: AdvertisementModel = new AdvertisementClass();

          tempAdv.adMediaType = 2;
          tempAdv.campaignDate = '';
          tempAdv.campaignId = 0;
          tempAdv.campaignSlot = 2;
          tempAdv.campaignTimeLineId = 0;
          tempAdv.mediaId = 11;
          tempAdv.mediaType = 0;
          tempAdv.timeSlot = 0;
          tempAdv.url = this.defaultAdVideo;
          this.advList.push(tempAdv);
        }

        if (!this.advList.find((x) => x.campaignSlot == 1)) {
          console.log("inside slot 4")
          let tempAdv: AdvertisementModel = new AdvertisementClass();

          tempAdv.adMediaType = 2;
          tempAdv.campaignDate = '';
          tempAdv.campaignId = 0;
          tempAdv.campaignSlot = 1;
          tempAdv.campaignTimeLineId = 0;
          tempAdv.mediaId = 11;
          tempAdv.mediaType = 0;
          tempAdv.timeSlot = 0;
          tempAdv.url = this.defaultAdVideo;
          this.advList.push(tempAdv);
        }
      
    }
    console.log('>>>>>>toSetAdvList>>>>>>>>>', this.advList);
    this.toSetAdvActive();
  }

  toSetAdvActive() {
    // for (let index = 0; index < this.advList.length; index++) {

    //   const adv = this.advList[index];
    //   console.log(">>>>>>toSetAdvActive>>>>>>>>>", adv);
    //   let nextSlot = 0;
    //   let lastSlot = 0;
    //   if (index == 0) {
    //     nextSlot = 0;
    //     lastSlot = 0;
    //   } else {
    //     if (adv.campaignSlot == 1) {
    //       nextSlot = 20000;
    //       lastSlot = 40000;
    //     } else {
    //       nextSlot = 40000;
    //       lastSlot = 20000;
    //     }
    //   }
    //   console.log("1111111111 index=" + index + "\n nextSlot=" + nextSlot + "\n lastSlot=" + lastSlot + "\nelement=" +JSON.stringify(adv));
    //   setTimeout(() => {
    //     console.log("22222222222 index=" + index + "\n nextSlot=" + nextSlot + "\n lastSlot=" + lastSlot + "\nelement=" + JSON.stringify(adv));
    //     this.productService.adActiveMediaType = adv.adMediaType;
    //     this.productService.adActiveMediaURL = adv.url;
    //     if (index == this.advList.length - 1) {
    //       setTimeout(() => {
    //         this.toSetAdvActive();
    //       }, lastSlot);
    //     }
    //   }, nextSlot);
    // }

    //2nd Methord
    if (this.advActivePosition < this.advList.length - 1) {
      this.advActivePosition = this.advActivePosition + 1;
    } else {
      this.advActivePosition = 0;
    }
    var nextSlot = 15000;
    const adv = this.advList[this.advActivePosition];

    var today = new Date();
    var date =
      today.getFullYear() +
      '-' +
      (today.getMonth() + 1) +
      '-' +
      today.getDate();
    var time =
      today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();
    var dateTime = date + ' ' + time;

    console.log(dateTime);


    this.productService.adActiveMediaType = adv.adMediaType;
    this.productService.campaignSlot = adv.campaignSlot;
    this.productService.adActiveMediaURL = adv.url;
    if(adv.adMediaType==2){
      setTimeout(()=>{
           const video:any = document.getElementById('video');
        video.muted=true;
    video.load();
    video.play();
      },100);
 
    }
// source.setAttribute('src', this.productService.adActiveMediaURL);
    // if (adv.campaignSlot == 1) {
    //   this.productService.adActiveMediaURL = adv.url;
    //   this.productService.adActiveMediaURL2 = '';
    // } else {
    //   this.productService.adActiveMediaURL = adv.url;
    //   this.productService.adActiveMediaURL2 = adv.url;
    // }

    console.log(
      '5555555 this.productService.adActiveMediaType =' +
        this.productService.adActiveMediaType +
        '\n this.productService.campaignSlot=' +
        this.productService.campaignSlot +
        '\n this.productService.adActiveMediaURL=' +
        this.productService.adActiveMediaURL 
    );

    setTimeout(() => {
      this.toSetAdvActive();
    }, nextSlot);
  }

  scanBarcode() {
    if (this.connectivity.networkIsConnected == true) {
      if (this.productService.availableProducts.length == 0) {
        this.router.navigate(['qr-scan']);
      } else {
        this.alertController
          .create({
            header: 'Alert',
            message: 'Session Inprogress!!',
            backdropDismiss: false,
            buttons: [
              {
                text: 'OK',
                role: 'cancel',
                handler: () => {
                  console.log('Application exit prevented!');
                  this.router.navigate(['tabs']);
                },
              },
            ],
          })
          .then((alert) => {
            alert.present();
          });

        //alert("Session in progress")
      }
    } else {
      this.presentToast('No Internet Connection!!');
    }
  }
  async presentToast(msg: any) {
    const toast = await this.toastController.create({
      message: msg,
      duration: 2000,
      icon: 'information-circle',
    });
    toast.present();
  }
}
