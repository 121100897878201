export interface AdvertisementModel {
    mediaType: number;
    image: string;
    campaignDate: string;
    timeSlot: number;
    campaignTimeLineId: number;
    campaignSlot: number;
    adMediaType: number;
    campaignId: number;
    mediaId: number;
    url: string;

}

export class AdvertisementClass implements AdvertisementModel {
    mediaType: number;
    image: string;
    campaignDate: string;
    timeSlot: number;
    campaignTimeLineId: number;
    campaignSlot: number;
    adMediaType: number;
    campaignId: number;
    mediaId: number;
    url: string;

    // constructor(mediaType: number, image: string) {
    //     this.mediaType = mediaType;
    //     this.image = image;
    // }
};
