import { Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Platform } from '@ionic/angular';
import { Observable } from 'rxjs';
import { ConnectService, DesignNames } from 'src/app/service/connect/connect.service';
import { environment } from '../../../environments/environment';
import { Storage } from '@ionic/storage';

@Injectable({
  providedIn: 'root'
})
export class WsConnectGuard implements CanActivate {


  constructor(private storage: Storage,private connectService: ConnectService, private router: Router, public platform: Platform) { 
    this.storage.create()

    this.storage.get('userType').then((val) => {
      this.connectService.userLoginType = val
    });
    this.storage.get('userToken').then((val) => {
      this.connectService.userToken = val
    });
    this.storage.get('userId').then((val) => {
      this.connectService.userId = val
    });
    
  }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    console.log("platformURL=" + this.platform.url() + "routerConfigPath" + route.routeConfig.path);
    
    if (!this.platform.url().startsWith(environment.webURL) && route.routeConfig.path === '' && (this.platform.is('android') || this.platform.is('ios'))) {
      this.connectService.platformType="mobile"
      if (!this.connectService.trioAccessToken) {
          return this.router.createUrlTree(['login']);
       } else {
        return this.router.createUrlTree(['tabs']);
      }

    } else if (route.routeConfig.path === 'connect/:trio-access-token') {
      this.connectService.platformType="browser"
      this.connectService.trioAccessToken = route.params['trio-access-token'];
      return this.router.createUrlTree(['/']);
    } else if (!this.connectService.trioAccessToken) {
      this.connectService.currentDesign = DesignNames.SessionExpired.toString();
      return this.router.createUrlTree(['status-display']);
    }
    return true;
  }

}
