import { ProductBasicInfo } from "./available-products";

export interface CartDataModel {
    trioCustId: String;
    trioOrderId: String;
    productDispenseInfo: Array<ProductBasicInfo>;


}

export class CartDataClass implements CartDataModel {
    trioCustId: String;
    trioOrderId: String;
    productDispenseInfo: ProductBasicInfo[];

};
