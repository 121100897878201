import { Component } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { Platform} from '@ionic/angular';
import { Platforms } from '@ionic/core';
import { ConnectService } from './service/connect/connect.service';
import { ProductService } from 'src/app/service/product/product.service';
import { NetworkService } from './service/utility/network.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  unSupportedPlatform: boolean;
  showTopNavBar: boolean = true;
  showImage: boolean;
  showLogo: boolean;
  buttonColor: string = '#transparent'; //Default Color

  public timer = 60;
  public m = 1;
  public progress = 0;

 
  constructor(private platform: Platform, private router: Router, public productService: ProductService,private connectivity: NetworkService) {
    //    setInterval(() => {
    //   this.progress += 0.01;

    
    //   // Reset the progress bar when it reaches 100%
    //   // to continuously show the demo
    //   if (this.progress > 1) {
    //     setTimeout(() => {
    //       this.progress = 0;
    //     }, 1000);
    //   }
    // }, 50);
  }

  initializeApp() {
    this.platform.ready().then(() => {
    
      this.platform.backButton.subscribeWithPriority(0, () => {
        navigator['app'].exitApp();
       
     });
    });
  }

  ionViewWillEnter(){
  //  this.routerOutlet.swipeGesture = false;

  }

//   ionViewDidEnter(){
//     this.routerOutlet.swipeGesture = false;
// }

// ionViewDidLeave(){
//     this.routerOutlet.swipeGesture = true;

//   }

  ngOnInit() {
    this.checkNetwork()

  
    // this.start()

    //this.initializeApp() 
    // if (!this.platform.is('mobileweb')) {
    //   this.unSupportedPlatform = true;
    // }
    //this.productService.availableProducts = [];
   // this.routerOutlet.swipeGesture = false;
    this.showImage = true;
    this.showLogo = false;

    setTimeout(()=>{  
      this.showImage = false;
      this.showLogo = false;
    },6000);
    setTimeout(()=>{  
      this.showLogo = true;
    },3500);

      this.router.events.subscribe((event) => {
        // console.log(event); // If you see the log here, You can get lot of events. So based on your requirement you can able to filter
        if (event instanceof NavigationStart) {
          if (((event as NavigationStart).url === '/') || ((event as NavigationStart).url === '/status-display')|| ((event as NavigationStart).url === '/status-payment')|| ((event as NavigationStart).url === '/qr-scan')|| ((event as NavigationStart).url === '/login') || ((event as NavigationStart).url === '/privacy-policy') || ((event as NavigationStart).url === '/terms-of-use') || ((event as NavigationStart).url === '/freq-asked-questions') || ((event as NavigationStart).url === '/edit-profile') || ((event as NavigationStart).url === '/rewards'))
            this.showTopNavBar = false;
          else if (!this.showTopNavBar)
            this.showTopNavBar = true;
        }
      });
  }

  goToCartDetails() {
    this.buttonColor = '#464646'; 
    setTimeout(() => {   
      this.buttonColor = 'transparent'; 

    },300);
    this.router.navigate(['cart-details']);
  }

  getCartCount() {
    let cartCount: number = 0;
    this.productService.cartProducts?.forEach(a => cartCount += a.quantity);
    return cartCount;
  }

  checkNetwork(){
    this.connectivity.appIsOnline$.subscribe(online => {

      console.log(online)

      if (online) {
          this.connectivity.networkIsConnected = true
          console.log("App is online")
          return true

      } else {
        this.connectivity.networkIsConnected = false
          console.log("App is offline")
          return false

      }

    })

  }
  

  // start() {

  //   var IntervalVar = setInterval(function () {

  //     this.timer--;

  //     if (this.timer === 0) {

  //       this.timer = 60;

  //       this.m -= 1;

  //     }

  //     if (this.m === 0) {

  //       this.timer = "00";

  //       this.m = "00"

  //       clearInterval(IntervalVar);

  //     }

  //   }.bind(this), 1000)
  // }


}
