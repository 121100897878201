import { Component, OnInit } from '@angular/core';
import { ConnectService, DesignNames } from 'src/app/service/connect/connect.service';
import { ProductService } from 'src/app/service/product/product.service';

import { BarcodeScanner, BarcodeScannerOptions, BarcodeScanResult } from '@ionic-native/barcode-scanner/ngx';
import { Router } from '@angular/router';
import { Platform } from '@ionic/angular';

@Component({
  selector: 'app-status-payment',
  templateUrl: './status-payment.page.html',
  styleUrls: ['./status-payment.page.scss'],
})
export class StatusPaymentPage implements OnInit {

  constructor(public connectService: ConnectService, public productService: ProductService, private barcodeScanner: BarcodeScanner, private router: Router, private platform: Platform) { 

    console.log("platformType"+this.connectService.platformType)
    
  }

  ngOnInit() {
  }

}
