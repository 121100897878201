import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { BackgroundMode } from '@awesome-cordova-plugins/background-mode/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { BarcodeScanner } from '@ionic-native/barcode-scanner/ngx';
import { HttpClientModule } from '@angular/common/http';
import { IonIntlTelInputModule } from 'ion-intl-tel-input';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { FormGroup, FormControl, Validators,FormBuilder}   from '@angular/forms';
import { IonicStorageModule } from '@ionic/storage-angular';
import {  
  FileTransfer,  
} from '@ionic-native/file-transfer/ngx/index';  
import {  
  DocumentViewer
} from '@ionic-native/document-viewer/ngx';  

import { FileOpener } from '@ionic-native/file-opener/ngx';

import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';

// IMPORT Google SIGN IN
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFireModule } from '@angular/fire/compat';
import { GooglePlus } from '@ionic-native/google-plus/ngx';
import { environment } from 'src/environments/environment';
import { SocialSharing } from '@awesome-cordova-plugins/social-sharing/ngx';
// IMPORT APPLE SIGN IN
import { SignInWithApple } from "@ionic-native/sign-in-with-apple/ngx";

// IMPORT SQLite
import {  SQLite  } from '@ionic-native/sqlite/ngx';

// IMPORT Camera
import { File } from '@ionic-native/file/ngx';
import { Camera } from '@ionic-native/Camera/ngx';

import { HTTP } from '@awesome-cordova-plugins/http/ngx';
@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [ IonIntlTelInputModule,FormsModule,ReactiveFormsModule,HttpClientModule,BrowserModule, IonicStorageModule.forRoot(),IonicModule.forRoot({hardwareBackButton:false}), AppRoutingModule, AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireAuthModule],
  providers: [ BackgroundMode,InAppBrowser,GooglePlus,FileTransfer,Validators,FormBuilder,HTTP,Camera,
    File,SignInWithApple,SocialSharing,SQLite ,{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy },BarcodeScanner,DocumentViewer,FileOpener],
  bootstrap: [AppComponent],
})
export class AppModule {}
