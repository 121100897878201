import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { WsConnectGuard } from './guard/connect/ws-connect.guard';

const routes: Routes = [

  {
    path: 'connect/:trio-access-token',
    canActivate:[WsConnectGuard],
    children:[]
  },
  {
    path: '',
    canActivate:[WsConnectGuard],
    loadChildren: () => import('./page/web-landing/web-landing.module').then(m => m.WebLandingPageModule)
  }, 
  {
    path: 'tabs',
    // canActivate:[WsConnectGuard],
    loadChildren: () => import('./page/tabs/tabs.module').then(m => m.TabsPageModule)
  },
  {
    path: 'home',
    canActivate:[WsConnectGuard],
    loadChildren: () => import('./page/home/home.module').then(m => m.HomePageModule)
  },
  {
    path: 'product-details',
    canActivate:[WsConnectGuard],
    loadChildren: () => import('./page/product-details/product-details.module').then(m => m.ProductDetailsPageModule)
  },
  {
    path: 'cart-details',
   // canActivate:[WsConnectGuard],
    loadChildren: () => import('./page/cart-details/cart-details.module').then(m => m.CartDetailsPageModule)
  },
  {
    path: 'status-display',
    loadChildren: () => import('./page/status-display/status-display.module').then( m => m.StatusDisplayPageModule)
  },
  {
    path: 'session-expired',
    loadChildren: () => import('./page/session-expired/session-expired.module').then( m => m.SessionExpiredPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./page/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'qr-scan',
    loadChildren: () => import('./page/qr-scan/qr-scan.module').then( m => m.QrScanPageModule)
  },
  {
    path: 'order-details',
    loadChildren: () => import('./page/order-details/order-details.module').then( m => m.OrderDetailsPageModule)
  },
  {
    path: 'privacy-policy',
    loadChildren: () => import('./page/privacy-policy/privacy-policy.module').then( m => m.PrivacyPolicyPageModule)
  },
  {
    path: 'terms-of-use',
    loadChildren: () => import('./page/terms-of-use/terms-of-use.module').then( m => m.TermsOfUsePageModule)
  },
  {
    path: 'freq-asked-questions',
    loadChildren: () => import('./page/freq-asked-questions/freq-asked-questions.module').then( m => m.FreqAskedQuestionsPageModule)
  },
  {
    path: 'profile',
    loadChildren: () => import('./page/profile/profile.module').then( m => m.ProfilePageModule)
  },
  {
    path: 'invite-friends',
    loadChildren: () => import('./page/invite-friends/invite-friends.module').then( m => m.InviteFriendsPageModule)
  },
  {
    path: 'my-order',
    loadChildren: () => import('./page/my-order/my-order.module').then( m => m.MyOrderPageModule)
  },
  {
    path: 'edit-profile',
    loadChildren: () => import('./page/edit-profile/edit-profile.module').then( m => m.EditProfilePageModule)
  },
  {
    path: 'scan-trio',
    loadChildren: () => import('./page/scan-trio/scan-trio.module').then( m => m.ScanTrioPageModule)
  },
  {
    path: 'rewards',
    loadChildren: () => import('./page/rewards/rewards.module').then( m => m.RewardsPageModule)
  },
  {
    path: 'status-payment',
    loadChildren: () => import('./page/status-payment/status-payment.module').then( m => m.StatusPaymentPageModule)
  },
  {
    path: '**' , 
    redirectTo:''
    //place this atlast to handel unknown URLs
  }

  

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
