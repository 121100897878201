// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // socketURL:'http://192.168.1.100:8081/ws',
 // socketURL:'https://vmswsalpha.trinityaxistrio.com/ws',
 // webURL:'https://vmsremotealpha.trinityaxistrio.com/',
 //QA
  //socketURL:'https://vmswsqa.trinityaxistrio.com/ws',
  //  webURL:'https://vmsremoteqa.trinityaxistrio.com/',

 //PRE-ALPHA
 //socketURL:'https://vmswsprealpha.trinityaxistrio.com/ws',
 //webURL:'https://vmsremoteprealpha.trinityaxistrio.com/',
 //baseUrl = "https://vmsapiprealpha.trinityaxistrio.com/api/"

  //SA
  socketURL:'https://vmswsqa.trinityaxistrio.com/ws',
  webURL:'https://vmsremoteqa.trinityaxistrio.com/',
  baseUrl:"https://vmsapiqa.trinityaxistrio.com/api/",
  firebaseConfig : {
    apiKey: "AIzaSyDGdojUaQTTjgUhCJ8JLZc580lZUmqDu-s",
    authDomain: "trio-2dd1b.firebaseapp.com",
    projectId: "trio-2dd1b",
    storageBucket: "trio-2dd1b.appspot.com",
    messagingSenderId: "215192900264",
    appId: "1:215192900264:web:65cc501506e7827d95e89d",
    measurementId: "G-HWQ5XJJJ1B"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
